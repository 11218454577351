import React, { useCallback, useEffect, useState } from 'react'
import { MasonryLayout } from '../../../../../components/layouts'
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material'
import { CustomButton } from '../../../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faCircleExclamation,
  faCircleCheck,
  faPen,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { OffreTable } from '../../../../../components/tables'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import '../../../../../styles/components/_box.scss'
import BiBlockTable from '../../../../../components/tables/BiBlockTable'
import moment from 'moment/moment'
import { useNavigate, useParams } from 'react-router-dom'
import { faArrowLeft, faHome } from '@fortawesome/pro-solid-svg-icons'
import {
  ChoiceOfferModal,
  EditDocuments,
  EditInfos,
  DeleteGeneralModal,
  EditList,
  EditBesoin,
  EditEvent,
  EditPasswordModal,
} from '../../../../../components/modals'
import { useForm } from 'react-hook-form'
import {
  ROLE_CANDIDAT,
  ROLE_ENTREPRISE,
  SOFTSKILLS,
  VALEURS,
  disponibilitesList2,
  disponibleSousList2,
} from '../../../../../data'
import EditResume from '../../../../../components/modals/EditResume'
import EditCommentaire from '../../../../../components/modals/EditCommentaire'
import GedTabs from '../../../../../components/tables/GedTabs'
import { useTranslation } from 'react-i18next'

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const ProspectCandidateProfil = () => {
  const { i18n } = useTranslation()
  const [openEvent, setOpenEvent] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openCommentaire, setOpenCommentaire] = useState(false)
  const [openResume, setOpenResume] = useState(false)
  const [openValeurs, setOpenValeurs] = useState(false)
  const [openCompetences, setOpenCompetences] = useState(false)
  const [openCompetencesAnnexes, setOpenCompetencesAnnexes] = useState(false)
  const [openSoftskils, setOpenSoftskils] = useState(false)
  const [loading, setLoading] = useState(true)
  const [snapShot, setSnapShot] = useState(null)
  const [loadingTest, setLoadingTest] = useState(false)
  const [loadingInscription, setLoadingInscription] = useState(false)
  const [besoin, setBesoin] = useState([])
  const [resume, setResume] = useState([])
  const [commentaire, setCommentaire] = useState([])
  const [dateRdv, setDateRdv] = useState([])
  const [dateRdvPasse, setDateRdvPasse] = useState([])
  //const [dateRelation, setDateRelation] = useState([])
  const [isFile, setIsFile] = useState([])
  const [role, setRole] = useState('')
  const [event, setEvent] = useState({})
  let navigate = useNavigate()

  const params = useParams()

  const handleOpenEvent = (event) => {
    setEvent(event)
    setOpenEvent(true)
  }
  const handleCloseEvent = () => {
    setOpenEvent(false)
    fetchData()
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    fetchData()
  }
  const handleOpenDoc = () => setOpenDoc(true)
  const handleCloseDoc = () => {
    setOpenDoc(false)
    fetchData()
  }
  const handleOpenCommentaire = () => setOpenCommentaire(true)
  const handleCloseCommentaire = () => {
    setOpenCommentaire(false)
    fetchData()
  }
  const handleOpenBesoin = () => setOpenBesoin(true)
  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    fetchData()
  }
  const handleOpenResume = useCallback(() => setOpenResume(true), [])
  const handleCloseResume = useCallback(() => {
    setOpenResume(false)
    //fetchData()
  }, [])
  const handleOpenCompetences = () => setOpenCompetences(true)
  const handleCloseCompetences = () => {
    setOpenCompetences(false)
    fetchData()
  }
  const handleOpenCompetencesAnnexes = () => setOpenCompetencesAnnexes(true)
  const handleCloseCompetencesAnnexes = () => {
    setOpenCompetencesAnnexes(false)
    fetchData()
  }
  const handleOpenValeurs = () => setOpenValeurs(true)
  const handleCloseValeurs = () => {
    setOpenValeurs(false)
    fetchData()
  }
  const handleOpenSoftskils = () => setOpenSoftskils(true)
  const handleCloseSoftskils = () => {
    setOpenSoftskils(false)
    fetchData()
  }

  const handleOpenOffer = () => setOpenOffre(true)
  const handleCloseOffer = () => {
    setOpenOffre(false)
    fetchData()
  }
  const handleSave = () => {
    fetchData()
    setOpen(false)
    setOpenDoc(false)
    setOpenBesoin(false)
    setOpenResume(false)
    setOpenCommentaire(false)
    setOpenCompetences(false)
    setOpenCompetencesAnnexes(false)
    setOpenSoftskils(false)
    setOpenValeurs(false)
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => removeFile()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [fileDelete, setFileDelete] = useState()

  const [openOffre, setOpenOffre] = useState(false)
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  //const [loadingOffer, setLoadingOffer] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const { handleSubmit } = useForm({ mode: 'onBlur' })

  /* const saveEvent = () => {
    role === ROLE_ENTREPRISE
      ? navigate(
          `/admin/gestion-client/profil-client-entreprise/${params.userId}`
        )
      : navigate(
          `/admin/gestion-client/profil-client-candidat/${params.userId}`
        )
  } */

  useEffect(() => {
    if (params.userId) fetchData()
  }, [params.userId])

  const fetchData = async () => {

    setLoading(true)
    try {
      let result = await Api.user.detail(params.userId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setLoading(false)
        const currentDate = new Date()
        setDateRdv(
          res.candidat?.calendriers
            .filter((item) => currentDate < new Date(item.dateDebut))
            .sort((a, b) => a.id - b.id)
        )
        setDateRdvPasse(
          res.candidat?.calendriers
            .filter((item) => new Date(item.dateDebut) < currentDate)
            .sort((a, b) => a.id - b.id)
        )
        setBesoin(res.candidat?.besoin)
        setResume(res.candidat?.resume)
        setCommentaire(res.commentaire)
        setRole(res.role)
        let userOffres = []
        if (res.userOffres) {
          res.userOffres.map((userOffre, i) => {
            userOffres.push({
              id: userOffre.id,
              offre: `/api/offres/${userOffre.offre.id}`,
              prix: userOffre.prix,
            })
          })
        }
        setOffresSelect(userOffres)
        let docs = []
        //if (res.candidat?.cv) docs.push({ type: 'cv', nom: res.candidat?.cv })
        if (res.documents) {
          res.documents.map((doc, i) => {
            docs.push(doc)
          })
        }

        setIsFile(docs)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  useEffect(() => {
    getOffers()
  }, [])
  const getOffers = async () => {
    try {
      let result = await Api.offer.listWithFilter({
        categorie: 'candidat',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        //setLoadingOffer(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        //setLoadingOffer(false)
        setOffres(res)
      } else {
        //setLoadingOffer(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      //setLoadingOffer(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const handleChangeSelect = (evt) => {
    const inputValue = evt.target.value
    setOffreSelect(inputValue)
  }

  const onSubmit = async (data) => {
    setLoadingButton(true)

    offresSelect.push({
      offre: `/api/offres/${offreSelect.id}`,
      prix: offreSelect.prix,
      status: 'nouveau',
    })
    setOffresSelect(offresSelect)

    try {
      const result = await Api.user.edit(snapShot.id, {
        userOffres: offresSelect,
        ajoutOffre: true,
      })

      const handling = handlingErrors(result)

      if (handling.ok) {
        //const res = handling.data
        //setSnapShot(res);
        setLoadingButton(false)
        setOpenOffre(false)
        fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }

  const sendEvaluation = async () => {
    setLoadingTest(true)

    try {
      const result = await Api.candidate.sendEval(params.userId, {
        url: 'https://www.youtube.com/channel/UC8YZ2pn8zIzx_GnJw_F6fdQ',
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        toast.success('Le test de personnalité a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
      setLoadingTest(false)
    } catch (error) {
      setLoadingTest(false)
    }
  }

  const sendInscriptionPropos = async () => {
    setLoadingInscription(true)

    try {
      const result = await Api.user.edit(params.userId, {
        status: 'proposition_inscription',
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        toast.success('La proposition a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
      setLoadingInscription(false)
    } catch (error) {
      setLoadingInscription(false)
    }
  }

  const handleRemove = (idFile) => {
    setOpenDeleteModal(true)
    setFileDelete(idFile)
  }

  const removeFile = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.document.delete(fileDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexFile = isFile.findIndex((file) => file.id === fileDelete)

        if (indexFile > -1) {
          isFile.splice(indexFile, 1)
        }
        setIsFile(isFile)
        setOpenDeleteModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const handleOpenPassword = () => setOpenPasswordModal(true)
  const handleClosePassword = () => setOpenPasswordModal(false)

  const onSuccessChangePassword = () => {
    toast.success('Le mot de passe a été modifié avec succès !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    window.scrollTo(0, 0)
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() =>
          navigate('/admin/gestion-prospect/liste-prospects-candidats')
        }
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>
      <MasonryLayout
        type={'candidat'}
        handleOpenDoc={handleOpenDoc}
        handleOpenBesoin={handleOpenBesoin}
        handleOpenResume={handleOpenResume}
        handleOpenCommentaire={handleOpenCommentaire}
        handleOpenValeurs={handleOpenValeurs}
        handleOpenCompetences={handleOpenCompetences}
        handleOpenCompetencesAnnexes={handleOpenCompetencesAnnexes}
        handleOpenSoftskils={handleOpenSoftskils}
        handleOpenOffer={handleOpenOffer}
        profil={
          <ProfilContainer>
            <TitleContainer>
              <TitleSection>Profil</TitleSection>
              <IconContainer onClick={handleOpen}>
                <FontAwesomeIcon
                  icon={faPen}
                  fontSize={15}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>
            <WrapperProfilText>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
              >
                {snapShot.prenom} {snapShot.nom}
              </Typography>
              {snapShot.candidat?.poste && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {snapShot.candidat.poste.titre}
                </Typography>
              )}
              {snapShot.candidat?.experiences && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {snapShot.candidat.experiences}
                </Typography>
              )}
              {snapShot.candidat?.typeDisponibilite && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {
                    disponibilitesList2.find(
                      (dispo) =>
                        dispo.id === snapShot.candidat?.typeDisponibilite
                    ).titre[i18n.language]
                  }

                  {snapShot.candidat?.typeDisponibilite === 2 &&
                    snapShot.candidat?.disponibleSous &&
                    disponibleSousList2.find(
                      (dispoS) => dispoS.id === snapShot.candidat?.disponibleSous
                    ).titre[i18n.language]}

                  {snapShot.candidat?.typeDisponibilite === 4 &&
                    moment(snapShot.candidat?.disponible)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY')}
                </Typography>
              )}
            </WrapperProfilText>
            <BannerProfilContainer>
              <WrapperBanner>
                {/* {snapShot.langues?.length > 0 && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faComment} />
                    <Typography>{snapShot.langues}</Typography>
                  </BannerProfil>
                )} */}

                <BannerProfil>
                  <FontAwesomeIcon icon={faHome} />
                  <Typography>
                    {snapShot.ville} / {snapShot.pays}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <Typography
                    textAlign={'right'}
                    title={snapShot.email}
                    style={{
                      width: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {snapShot.email}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faPhone} />
                  <Typography>{snapShot.telephone}</Typography>
                </BannerProfil>
                {loadingTest ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={sendEvaluation}
                  >
                    Test de personnalité
                  </CustomButton>
                )}
                {loadingInscription ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={sendInscriptionPropos}
                  >
                    Proposer l'inscription
                  </CustomButton>
                )}
              </WrapperBanner>
              {/*<BorderButtonPurple>Voir le résumé</BorderButtonPurple>*/}
            </BannerProfilContainer>
          </ProfilContainer>
        }
        besoinClient={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            <div style={{ whiteSpace: 'pre-line' }}>{besoin}</div>
          </Typography>
        }
        resumeClient={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            <div style={{ whiteSpace: 'pre-line' }}>{resume}</div>
          </Typography>
        }
        commentaireAdmin={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            <div style={{ whiteSpace: 'pre-line' }}>{commentaire}</div>
          </Typography>
        }
        rendezVous={
          <BiBlockTable
            rdvAVenir={dateRdv}
            rdvPasse={dateRdvPasse}
            heureAVenir={''}
            handleOpenEvent={handleOpenEvent}
            type={'candidat'}
          />
        }
        relation={<BiBlockTable rdvAVenir={[]} heureAVenir={''} />}
        feedback={dateRdv}
        offre={
          snapShot.userOffres && (
            <OffreTable
              offres={snapShot.userOffres}
              dateOffer={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY HH:mm')}
            />
          )
        }
        ged={
          isFile ? (
            <GedTabs
              files={isFile}
              dateFile={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY HH:mm')}
              handleRemove={handleRemove}
            />
          ) : (
            <GedTabs />
          )
        }
        displayParams={true}
        displayCompetencesSection={'display'}
        competences={snapShot.candidat.competences}
        competencesAnnexes={snapShot.candidat.competencesAnnexes}
        displaySoftskilSection={'display'}
        softskils={snapShot.candidat.softskils}
        displayValeurSection={'display'}
        valeurs={snapShot.candidat.valeurs}
      />

      {open && (
        <EditInfos
          open={open}
          onClose={handleClose}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_ADMIN'}
          type={role}
          handleOpenPassword={handleOpenPassword}
        />
      )}

      {openPasswordModal && (
        <EditPasswordModal
          open={openPasswordModal}
          onClose={handleClosePassword}
          id={params.userId}
          onSuccess={onSuccessChangePassword}
        />
      )}

      {openCommentaire && (
        <EditCommentaire
          open={openCommentaire}
          onClose={handleCloseCommentaire}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openResume && (
        <EditResume
          open={openResume}
          onClose={handleCloseResume}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openBesoin && (
        <EditBesoin
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openDoc && (
        <EditDocuments
          open={openDoc}
          onClose={handleCloseDoc}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_CANDIDAT'}
        />
      )}

      {openCompetences && (
        <EditList
          open={openCompetences}
          onClose={handleCloseCompetences}
          id={snapShot.candidat.id}
          type={'competences'}
          onSave={handleSave}
          values={snapShot.candidat.competences}
          datas={[]}
        />
      )}

      {openCompetencesAnnexes && (
        <EditList
          open={openCompetencesAnnexes}
          onClose={handleCloseCompetencesAnnexes}
          id={snapShot.candidat.id}
          type={'competencesAnnexes'}
          onSave={handleSave}
          values={snapShot.candidat.competencesAnnexes}
          datas={[]}
        />
      )}

      {openSoftskils && (
        <EditList
          open={openSoftskils}
          onClose={handleCloseSoftskils}
          id={snapShot.candidat.id}
          type={'softskils'}
          onSave={handleSave}
          values={snapShot.candidat.softskils}
          datas={[...SOFTSKILLS['fr'], ...SOFTSKILLS['en']].sort()}
        />
      )}

      {openValeurs && (
        <EditList
          open={openValeurs}
          onClose={handleCloseValeurs}
          id={snapShot.candidat.id}
          type={'valeurs'}
          onSave={handleSave}
          values={snapShot.candidat.valeurs}
          datas={[...VALEURS['fr'], ...VALEURS['en']]}
        />
      )}

      {openOffre && (
        <ChoiceOfferModal
          open={openOffre}
          onClose={handleCloseOffer}
          items={offres}
          handleClick={handleSubmit(onSubmit)}
          value={offreSelect}
          handleChange={handleChangeSelect}
          loading={loadingButton}
        />
      )}

      {openDeleteModal && (
        <DeleteGeneralModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteModal}
          loading={loadingDelete}
        />
      )}

      {openEvent && (
        <EditEvent
          open={openEvent}
          onClose={handleCloseEvent}
          id={params.userId}
          role={ROLE_CANDIDAT}
          event={event}
          onSave={handleCloseEvent}
          showParams={true}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default ProspectCandidateProfil
